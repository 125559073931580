<template>
  <v-main class="main">
    <router-view />

    <dashboard-core-footer />
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>

<style scoped>
  .main {
    background-color: #f3f3f3;
  }
</style>